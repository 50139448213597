.mapboxgl-marker {
  cursor: pointer;
}

.mapboxgl-popup-content {
  width: 384px;
  padding: 0;
}

.mapboxgl-popup-content .header {
  /*background-color: #f5f5f5;*/
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
}

.mapboxgl-popup-content .header .caption {
  font-style: italic;
}

.mapboxgl-popup-content .header, .mapboxgl-popup-content .sidebar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.mapboxgl-popup-content .sidebar {
  flex-direction: column;
}

.mapboxgl-popup-content .sidebar > div > div {
  height: 1.5rem;
}

.mapboxgl-popup-content .header, .mapboxgl-popup-content .footer {
  /*padding: $spacing-2 $spacing-1;*/
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
}

.mapboxgl-popup-content h2,
.mapboxgl-popup-content h3,
.mapboxgl-popup-content h4,
.mapboxgl-popup-content h5,
.mapboxgl-popup-content h6 {
    margin-top: 0;
    margin-bottom: 0;
}

.mapboxgl-popup-content .caption {
  margin: 0;
  font-weight: 300;
}


.mapboxgl-popup-content .point-popup img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  //border-radius: 4px;
}

.mapboxgl-popup-content .cluster-popup img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.mapboxgl-popup-content .cluster-popup .content {
  max-height: 384px;
  overflow-y: scroll;
}

.mapboxgl-popup-content .cluster-popup .content > div {
  padding: 0 8px;
  display: flex;
  /*align-items: flex-start;*/
  border-bottom: 1px solid #f5f5f5;
  background-color: #f5f5f5;
}

.mapboxgl-popup-content .cluster-popup .content > div:nth-child(even) {
  background-color: white;
}

.mapboxgl-popup-content .cluster-popup .content > div > div {
  flex-grow: 1;
  padding: 0.25rem 0.5rem;
}

.mapboxgl-popup-content .cluster-popup .content > div > div:last-child {
  text-align: right;
  flex-grow: 0;
}

.mapboxgl-popup-content .cluster-popup .content a {
  display: inline;
}

.mapboxgl-popup-content .TextButton {
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;

  display: inline-flex;
  cursor: pointer;
  border: 0;
  outline: 0;
  position: relative;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  -moz-user-select: none;
  background-color: transparent;
  -webkit-appearance: none;
}

.mapboxgl-popup-content .TextButton:hover {
   text-decoration: none;
   background-color: rgba(25, 118, 210, 0.04);
 }

.mapboxgl-popup-content .TextButton.primary {
  color: #26a69a;
 }

.mapboxgl-popup-content h2 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  font-size: 2.4em;
  line-height: 1.5em;
  letter-spacing: -0.00833em;
}

.mapboxgl-popup-content h3 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  font-size: 1.825em;
  line-height: 1.4em;
  letter-spacing: 0;
}

.mapboxgl-popup-content h4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  font-size: 1.3em;
  line-height: 1.4em;
  letter-spacing: 0.00735em;
}

.mapboxgl-popup-content h5 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1.25em;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.4em;
}

.mapboxgl-popup-close-button {
  font-size: 24px;
}
